<!-- 报告助手 个人报告 -->
<template>
    <div class="zhushou">
        <div class="logoBox">
            <div class="logoImg">
                <img src="../../assets/report//blueLogo.png" alt="">
                <img src="../../assets/report/redLogo.png" alt="">
            </div>
            <!-- <div c></div> -->
        </div>
        <div class="title">
            <h3>{{ userInfo.userName }}同学识写能力测评报告</h3>
            <div class="time flex-align-between">
                <div class="downloadPdf">
                    <i class="el-icon-download" @click="getUserpdf" v-if="reportStatus == 0"> 生成个人报告</i>
                    <span v-if="reportStatus == 1"> 生成报告中 <i class="el-icon-loading"></i></span>
                    <i class="el-icon-download" @click="downloadPdf" v-if="reportStatus == 2"> 下载个人报告</i>

                </div>
                <div>
                    <span>学期:{{ this.$route.query.termKeyName }}</span>
                    <span>报告时间:{{ currentTime }}</span>
                </div>
            </div>
        </div>
        <el-table :data="tableData2" :resizable="false" :span-method="arraySpanMethod" :header-cell-style="headerCellStyle">
            <el-table-column label="个人成绩查询与测评报告" width="160" align="center">
                <el-table-column label="占位" width="160" align="center">
                    <el-table-column prop="firstName" label="一级维度" align="center" width="100">
                    </el-table-column>
                    <el-table-column prop="setName" label="二级维度" align="center" width="100">
                    </el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column label="用户ID" width="160">
                <el-table-column width="120" :label="userInfo.userId + ''">
                    <el-table-column prop="setComment" label="三级维度" align="center" width="100">
                    </el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column label="用户名称">
                <el-table-column :label="userInfo.userName" width="120">
                    <el-table-column prop="setScore" label="成绩" align="center" width="80">
                    </el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column label="学校名称" align="center" width="200">
                <el-table-column :label="userInfo.schoolName" align="center" width="120">
                    <el-table-column prop="level" label="等级" align="center" width="80">
                        <template slot-scope="scope">
                            {{ scope.row.level }}
                            <span v-if="scope.row.level == '优秀+'" style="color: red;">↑</span>
                            <span v-if="scope.row.level == '待提升'" style="color: red;">↓</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="setDescribe" label="测评结果" align="center">
                    </el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column label="班级名称" align="center" width="200">
                <el-table-column :label="userInfo.className" align="center" width="120">
                    <el-table-column prop="setAdvice" label="提升建议" align="center">
                    </el-table-column>
                </el-table-column>
            </el-table-column>
        </el-table>
        <table border="1" class="myTable">
            <tr>
                <td style="width: 100px;" align="center">社会考级推荐</td>
                <td align="center">
                    <!-- <div>平均分:{{ (equalScore / 11).toFixed(2) }}</div>
                    <div>推荐报考:{{ advise((equalScore / 11).toFixed(2)) }}</div> -->
                    <div>成绩:{{ equalScore.toFixed(2) }}</div>
                    <div>推荐报考:{{ advise(equalScore) }}</div>
                </td>
                <td align="center">此测评以教育部书画等级为据,结果仅作参考,不具备权威性</td>
                <td align="center">主动学习书法专业知识与相关课程,积极参与社会考级,并获得权威认证</td>
            </tr>
        </table>
        <table border="1" class="myTable2">
            <tr>
                <td colspan="4" align="center" style="font-size: 22px;">{{ userInfo.userId + userInfo.userName + '同学试卷' }}
                </td>
            </tr>
            <!-- <tr style="display: flex;"> -->
            <tr class="flex-align-around">
                <!-- <img :src="userInfo.workList[0]" alt="" style="width: 500px;">
                <img :src="userInfo.workList[1]" alt="" style="width: 500px;"> -->
                <img :src="userInfo.workList[0]" alt="">
                <img :src="userInfo.workList[1]" alt="">
            </tr>
        </table>
    </div>
</template>
<script>
import axios from 'axios'
import moment from "moment";
export default {
    data() {
        return {
            userInfo: {
                userId: '',
                userName: '',
                schoolName: '',
                className: '',
                workList: []
            },
            tableData2: [{
                setLevel: ''
            }],
            level: {
                1: '优秀+',
                2: '优秀',
                3: '良好',
                4: '待提升'
            },
            spanArr: [],
            equalScore: 0,
            reportStatus: 0,
            currentTime: '',

        }
    },
    methods: {
        async getData() {
            let data = {
                userId: this.$route.query.userId,
                termKey: this.$route.query.termKey,
                examType: this.$route.query.reportValue,
                examId: this.$route.query.monthValue,
                version: this.$route.query.term_type
            }
            let resData = await this.$Api.DataScreen.getUserCommentReport(data);
            this.userInfo = resData.data;
            console.log('resData:', resData)
            resData.data.userCommentData.map(item => {
                let levels = {
                    4: '优秀+',
                    3: '优秀',
                    2: '良好',
                    1: '待提升'
                }
                item.level = levels[item.setLevel]
            })
            //平均分值
            this.equalScore = Number(resData.data.completeScore) * 0.1 + Number(resData.data.layoutScore) * 0.5 + Number(resData.data.structureScore) * 0.35 + Number(resData.data.formScore) * 0.05;
            // resData.data.userCommentData.map(item => {
            //     item.setScore = item.setScore.toFixed(2);
            //     if (item.firstName == '作品完整度') {

            //     } else {
            //         console.log('Number(item.setScore)',Number(item.setScore));
            //         this.equalScore += Number(item.setScore)
            //     }
            // })
            // this.equalScore += resData.data.completeScore;
            this.tableData2 = resData.data.userCommentData;
            // console.log('this.equalScore:', this.equalScore);

        },
        // 合并列
        getspanArr() {

        },
        arraySpanMethod({
            row,
            column,
            rowIndex,
            columnIndex
        }) {
            if (columnIndex === 0) {
                const currentValue = row[column.property];
                const preRow = this.tableData2[rowIndex - 1];
                const preValue = preRow ? preRow[column.property] : null;
                if (currentValue === preValue) {
                    return { rowspan: 0, colspan: 0 };
                } else {
                    let rowspan = 1;
                    for (let i = rowIndex + 1; i < this.tableData2.length; i++) {
                        const nextRow = this.tableData2[i];
                        const nextValue = nextRow[column.property];
                        if (nextValue === currentValue) {
                            rowspan++;
                        } else {
                            break;
                        }
                    }
                    return { rowspan, colspan: 1 }
                }
            }

        },

        //合并表头
        headerCellStyle(
            {
                row,
                column,
                rowIndex,
                columnIndex
            }
        ) {

            if (column.label == "个人成绩查询与测评报告") {
                this.$nextTick(() => {
                    document.querySelector(`.${column.id}`).setAttribute("rowSpan", "2");
                });
                return {
                    color: "#fff",
                    fontWeight: "bold",
                    backgroundColor: '#696969',
                    fontSize: '16px',
                    textAlign: 'center',
                };
            };
            if (column.label === "占位") {
                return {
                    display: "none"
                };
            }

        },
        // 考级推荐
        advise(score) {
            if (score < 75) {
                return '无考级推荐，继续努力'
            } else if (score >= 75 && score < 85) {
                return '硬笔一级'
            } else if (score >= 85 && score < 90) {
                return '硬笔二级'
            } else if (score >= 90 && score < 95) {
                return '硬笔三级'
            } else {
                return '硬笔四级'
            }
        },

        //下载pdf
        async downloadPdf() {
            let data = {
                userId: this.$route.query.userId,
                termIndex: this.$route.query.termKey,
                examType: this.$route.query.reportValue,
                examId: this.$route.query.monthValue,
                version: this.$route.query.term_type
            }
            let resData = await this.$Api.DataScreen.getDownloadpdf(data);
            if (resData.data) {
                this.$message({
                    message: '报告生成中',
                    center: true
                });
            } else {
                window.open(resData.data)
            }
        },
        //生成学生个人识写能力测评报告
        async getUserpdf() {
            let that = this;
            let data = {
                userId: this.$route.query.userId,
                termIndex: this.$route.query.termKey,
                examType: this.$route.query.reportValue,
                examId: this.$route.query.monthValue,
                version: this.$route.query.term_type
            }
            let resData = await this.$Api.DataScreen.getUserpdf(data);
            if (resData.code == 200) {
                this.reportStatus = 1;
                setTimeout(() => {
                    that.reportStatus = 2;
                }, 1500);
            } else {
                this.reportStatus = 1;
            }

            console.log('点击生成', resData)
        },
    },
    mounted() {
        this.getData();
        this.getspanArr();
        this.currentTime = moment().format("YYYY.MM.DD");
    },
};
</script>
<style lang='less' scoped>
@import url('./less/report_stu_zhushou.less');
</style>